<template>
  <Authenticated>
    <div class="floor-plan">
      <h1>{{ site }} floorplan</h1>
      <section class="header">
        <div class="spaces">
          <button class="space-btn btn" v-for="space in spaces" :key="space" @click.stop.prevent="handleSpace(space)">
            {{ formatName(space[0]["name"]) }}
          </button>
        </div>
        <div class="red" v-if="noSpaceFound">No floor plans found</div>
        <div><button class="btn" @click="addSpace = !addSpace">Create a Floor Plan</button></div>
      </section>
      <section>
        <form action="addSpace" v-if="addSpace" class="add-space">
          <label for="space" class="space-label"><h3>Space name:</h3></label>
          <input name="space" type="text" placeholder="...bar/dinning/outside" v-model="spaceName" id="space_name" class="space-input" />
          <p class="red" v-if="namingError">Please use a name without an '_'.</p>
          <button @click.stop.prevent="addNewSpace" class="add-space-btn">Add space</button>
        </form>
      </section>
      <section class="create-table">
        <h2>{{ formatName(currentSpace.name) }}</h2>
        <div class="create">
          <h3 class="blue">Create a Table:</h3>
          <form class="table">
            <div class="inner-table">
              <span class="radio-span">
                <input type="radio" name="tableType" id="square" v-model="shape" value="square" />
                <label for="square">Square</label>
              </span>
              <span class="radio-span">
                <input type="radio" name="tableType" id="rect" v-model="shape" value="rectangle" />
                <label for="rect">Rectangle</label>
              </span>
              <span class="radio-span">
                <input type="radio" name="tableType" id="circle" v-model="shape" value="circle" />
                <label for="circle">Circle</label>
              </span>
            </div>
            <div class="size">
              <label for="size">How many Seats?</label>
              <input type="number" name="tableSize" id="size" v-model="size" min="2" step="2" />
            </div>
          </form>
          <button @click.stop.prevent="generateTable">Generate</button>
          <p v-if="errors" class="red">{{ errors }}</p>
        </div>
        <div class="created">
          <div class="title">
            <h3 class="blue">Remove a Table</h3>
            <h3 class="blue">Total Tables: {{ tableLength }}</h3>
          </div>
          <div class="table-list">
            <div v-for="(table, idx) in new Array(tableLength)" :key="idx">
              <button class="delete-btn" @click.stop.prevent="deleteTable(idx + 1)">{{ idx + 1 }}</button>
            </div>
          </div>
        </div>
      </section>
      <section class="table-layout">
        <div class="head">
          <h3>{{ formatName(currentSpace.name) }} layout</h3>
          <div class="save">
            <p v-if="saved" class="blue">Layout saved</p>
          </div>
          <div class="zoom-in-out">
            <span size="xl" class="icon">Scale: {{ parseInt(scale * 100) }}%</span>
            <font-awesome-icon icon="minus" class="zoom-btn" @click.stop.prevent="zoomOut" />
            <font-awesome-icon icon="plus" class="zoom-btn" @click.stop.prevent="zoomIn" />
            <button @click.stop.prevent="saveLayout" class="btn">Save</button>
          </div>
        </div>
        <div v-if="portrait" class="reset-btn" @click.stop.prevent="resetPosition">Reset Position</div>
        <div class="layout">
          <div id="layout" class="dropzone"></div>
        </div>
      </section>
    </div>
  </Authenticated>
</template>

<script>
import interact from "interactjs";
import { SVG } from "@svgdotjs/svg.js";
import { mapGetters } from "vuex";
import createdMixin from "@/components/_mixins/createdMixin";
import { api } from "@/helpers/api";
import Authenticated from "../_layouts/Authenticated.vue";

export default {
  name: "FloorPlan",
  mixins: [createdMixin],
  components: { Authenticated },

  data() {
    return {
      site: this.$route.params.site,
      addSpace: false,
      showSpace: false,
      spaces: [],
      currentSpace: [],
      tables: [],
      shape: "",
      size: 2,
      floorPlan: [],
      draw: false,
      scale: 1,
      tableNumber: 1,
      spaceName: "",
      namingError: false,
      tableLength: 0,
      errors: false,
      saved: false,
      portrait: false,
      noSpaceFound: false,
      // currentTable: false,
    };
  },
  methods: {
    initializePortraitMode() {
      this.scale = 0.8;
      if (window.matchMedia("(orientation: portrait)").matches) {
        const draw = SVG("#layout svg");
        if (draw) {
          draw.css("width", "1000%");
          draw.css("height", "1000%");
          draw.css("transform-origin", "left top");

          const layout = document.querySelector("svg");
          if (layout) {
            layout.scrollLeft = (layout.scrollWidth - layout.clientWidth) / 2;
            layout.scrollTop = (layout.scrollHeight - layout.clientHeight) / 2;
          }
        }
      }
    },

   

    deleteTable(tableID) {
      console.log(tableID);
      let table = document.querySelector("#layout svg #table_" + tableID);
      table.remove();
      this.reorderTables();
      --this.tableNumber;
    },

    reorderTables() {
      let tables = document.querySelectorAll(".draggable");
      console.log(tables);
      tables.forEach((group, index) => {
        group.setAttribute("id", "table_" + (index + 1));
        group.setAttribute("table", index + 1);
        group.children.forEach((child) => {
          if (child.tagName == "circle" || child.tagName == "rect") {
            child.setAttribute("id", "shape_" + (index + 1));
          } else if (child.tagName == "text") {
            child.firstElementChild.textContent = index + 1;
          }
        });
      });
    },

    addNewSpace() {
      this.spaceName.includes("_") ? (this.nameError = true) : (this.nameError = false);
      if (!this.nameError) {
        let createdSpace = {
          name: "layout_" + this.spaceName,
          svg: SVG()
            .size("100%", "100%")
            .svg(),
        };
        this.spaces.push([createdSpace]);
        this.currentSpace = createdSpace;
        this.saveSetting(createdSpace.name, createdSpace.svg);
        this.addSpace = false;
        this.spaceName = "";
      } else {
        document.getElementById("space_name").style.border = "1px solid red";
        this.namingError = true;
      }
    },

    saveSetting(space, svg) {
      //console.log(this.currentSpace.svg);
      //this.spaces.map((s) => (s[0].name == space) ? s.svg = svg : null );
      this.channels.map((channel) => {
        let topic = channel.topic.split(":");
        if (topic[0] == "pos" && topic[2] == this.site) {
          channel.push("company:site:setting", { key: space, value: svg });
        }
      });
      this.saved = true;
    },

    async saveLayout() {
      let draw = SVG("#layout svg");
      if (this.currentSpace) {
        await this.saveSetting(this.currentSpace.name, draw.svg());
        this.currentSpace["svg"] = draw.svg();
        await this.getFloorPlan(false);
      }
    },

    async getFloorPlan(init = true) {
      let result = await api.get_floor_plan(this.companyName, this.site);
      result.length == 0 ? (this.noSpaceFound = true) : (this.noSpaceFound = false);

      if (Array.isArray(result) && result.length > 0) {
        if (init) {
          this.tableNumber = document.querySelectorAll(".draggable").length;
          this.currentSpace = result[0][0];
          this.spaceName = this.currentSpace.name;
        }
        this.spaces = result;

        if (this.currentSpace && this.currentSpace["svg"]) {
          if (init) {
            SVG(this.currentSpace["svg"])
              .addTo("#layout")
              .size("100%", "100%");
          } else {
            let draw = SVG("#layout svg");
            draw.svg(this.currentSpace["svg"], true);
          }
          this.rebindInteractions();
          return;
        }
      }
      this.initializeEmptyLayout();
    },

    initializeEmptyLayout() {
      this.draw = SVG()
        .addTo("#layout")
        .size("100%", "100%");
    },

    handleSpace(space) {
      this.saved = false;
      this.tableNumber = document.querySelectorAll(".draggable").length;

      if (space[0]["svg"]) {
        this.currentSpace = space[0];
        this.spaceName = space[0].name;
        let draw = SVG("#layout svg");
        draw.svg(space[0].svg, true);
        this.rebindInteractions();
      } else {
        this.initializeEmptyLayout();
        this.currentSpace = space[0];
      }
    },

    rebindInteractions() {
      let draw = SVG("#layout svg");
      let elements = draw.find(".draggable", ".tap-target");
      elements.forEach((el) => {
        this.scaleListener();
        interact(el.node)
          .on("tap", function(event) {
            // console.log(getTableInfo(event));
            event.preventDefault();
          })
          .on("doubletap", function(event) {
            let shape = SVG("#" + event.target.id);
            shape.transform({ rotate: 45 }, true);
          });
      });
    },

    zoomIn() {
      let draw = SVG("#layout svg");
      this.scale = this.scale + 0.1;
      draw.css("transform", "scale(" + this.scale + ")");

      this.scaleListener();
    },
    zoomOut() {
      let draw = SVG("#layout svg");
      this.scale = this.scale <= 0.15 ? 0.1 : this.scale - 0.1;
      draw.css("transform", "scale(" + this.scale + ")");
      if (this.scale < 1) {
        let scale = 100 / this.scale;
        draw.css("transform-origin", "left top");
        draw.css("transform", scale + "%");
      }
      this.scaleListener();
    },

    scaleListener() {
      let scale = this.scale < 1 ? this.scale : 1;

      interact(".draggable").draggable({
        inertia: true,
        listeners: {
          move(event) {
            let target = event.target;

            let x = (parseFloat(target.getAttribute("data-x")) || 0) + event.dx / scale;
            let y = (parseFloat(target.getAttribute("data-y")) || 0) + event.dy / scale;

            let rect = SVG("#" + target.id);
            rect.transform({ translateX: x, translateY: y }, true);
          },
        },
      });
    },

    addTableChairs(group, size, shape) {
      let chairs = [...Array(size)];
      let chairGroup = group.group();
      let angle = 0;
      // let squareSpacing = group.width() / 2 / (size / 4);
      let offset = -25;
      let side = 1;
      let currentX = 25;
      let currentY = 25;
      let origin = 25 * Math.sqrt(size);
      console.log(shape, chairs, angle, group.path());
      let chairCounter = 0;
      chairs.forEach(() => {
        chairCounter++;
        if (shape == "circle") {
          //let tableCenter = group.width() / 2;
          chairGroup
            .attr("id", "chairs")
            .circle(50)
            .attr("id", "chair_" + chairCounter)
            .attr({ fill: "var(--primary-colour)" })
            .attr("transform-origin", origin + " " + origin)
            .attr("transform", "rotate(" + angle + ") translate(-15)");
          angle += 360 / size;
        } else if (shape == "square") {
          chairGroup
            .attr("id", "chairs")
            .circle(50)
            .attr("id", "chair_" + chairCounter)
            .x(currentX)
            .y(offset)
            .attr("transform-origin", origin + " " + origin)
            .attr("transform", "rotate(" + angle + ")")
            .attr({ fill: "var(--primary-colour)" });
          // .transform("rotate(" + angle + ")");
          console.log(angle);
          //currentX += squareSpacing;
          if (side < size / 4) {
            currentX += 50;
            ++side;
          } else {
            angle = angle + 90;
            side = 1;
            currentX = 25;
          }
        } else if (shape == "rectangle") {
          chairGroup
            .circle(50)
            .attr({ fill: "var(--primary-colour)" })
            .x(-currentX)
            .y(currentY)
            .attr("transform-origin", origin + " " + 50 * Math.sqrt(this.size / 2))
            .attr("transform", "rotate(" + angle + ")");
          if (side == 1) {
            if (size == 2) {
              currentX += 25;
              side = 3;
              angle = 180;
            }
            ++side;
          } else if (side == 2) {
            if ((size - 2) / 2 == side) {
              angle = 180;
              ++side;
            }
          } else if (side == 3) {
            ++side;
            angle = angle + 90;
          }
        }
      });
    },

    addTableInfo(group, size) {
      group
        .text(this.tableNumber)
        .cx(group.width() / 2)
        .cy(group.height() / 2);
      group.addClass("draggable");
      group.addClass("tap-target");
      group.attr("table", this.tableNumber);
      group.attr("seats", size);
    },

    addTableNumber(group) {
      let existingTables = document.querySelectorAll(".draggable");
      if (existingTables.length > 0) {
        let lastTable = existingTables[existingTables.length - 1];
        this.tableNumber = parseInt(lastTable.getAttribute("table")) + 1;
        group.attr("id", "table_" + this.tableNumber);
      } else {
        this.tableNumber = 1;
        group.attr("id", "table_" + this.tableNumber);
      }
    },

    generateTable() {
      let tableScaling = Math.sqrt(this.size) / 2;

      if (this.shape == "") {
        this.errors = "Please select a table shape.";
        return;
      }

      let draw = SVG("#layout svg");

      let table = {
        shape: this.shape,
        size: this.size,
      };

      let group = draw.group();
      if (table.shape === "square") {
        this.addTableChairs(group, this.size, table.shape);
        group
          .rect(100 * tableScaling, 100 * tableScaling)
          .attr({ fill: "var(--action-colour)" })
          .attr("id", "shape_" + this.tableNumber)
          .attr("rx", (8 * tableScaling) / 2);
        this.addTableNumber(group);
        this.addTableInfo(group, this.size);
      } else if (table.shape === "rectangle") {
        this.addTableChairs(group, this.size, table.shape);
        this.addTableNumber(group);
        group
          .rect(100 * Math.sqrt(this.size / 2), 100)
          .attr({ fill: "var(--action-colour)" })
          .attr("id", "shape_" + this.tableNumber)
          .attr("rx", (8 * tableScaling) / 2);
        this.addTableInfo(group, this.size);
      } else if (table.shape === "circle") {
        this.addTableNumber(group);
        this.addTableChairs(group, this.size, table.shape);
        group.circle(100 * tableScaling).attr({ fill: "var(--action-colour)" });
        this.addTableInfo(group, this.size);
      }

      this.scaleListener();
      this.rebindInteractions();
      this.tableNumber += 1;
    },

    formatName(name) {
      if (name == undefined) {
        return "";
      }
      return name.split("_")[1];
    },
  },
  computed: {
    ...mapGetters({
      channels: "channels",
      companyName: "companyName",
    }),
  },
  watch: {
    tableNumber() {
      let tables = document.querySelectorAll(".draggable");
      this.tableLength = tables.length;
    },
  },
  async mounted() {
    await this.getFloorPlan();
    if (screen.orientation.type == "portrait-primary") {
      this.portrait = true;
      this.initializePortraitMode();
    }
    screen.orientation.addEventListener("change", (event) => {
      if (event.target.type == "portrait-primary") {
        this.portrait = true;
        this.$nextTick(() => {
          this.initializePortraitMode();
        });
      } else {
        this.portrait = false;
      }
    });
  },
};
</script>

<style scoped>
.blue {
  color: var(--action-colour);
}

h1 {
  text-transform: capitalize;
}

.red {
  margin: 0.5rem;
  color: red;
}

.table-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0.25rem 1rem;
}

.delete-btn {
  background-color: var(--warning-colour);
  margin: 0.25rem;
}
.add-space {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0.5rem;
  .space-input {
    border-radius: 10px;
    padding: 0.75rem;
    margin: 0.25rem;
    border: none;
    box-shadow: var(--box-shadow);
  }
  .add-space-btn {
    background-color: var(--action-colour);
    color: var(--primary-colour);
    border-radius: 10px;
    padding: 1rem;
    margin: 0.25rem;
    box-shadow: var(--box-shadow);
    cursor: pointer;
  }
}

.space-btn {
  text-transform: capitalize;
}

.floor-plan {
  height: 100%;
  width: 90vw;
  border-radius: 20px;
  padding: 1rem;
}

.save {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 20%;
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .spaces {
    display: flex;
    justify-content: space-evenly;
  }
}

.create-table {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background-color: var(--primary-colour);
  padding: 1rem;
  border-radius: 20px;
  flex-wrap: wrap;
  h2 {
    flex: 0 0 100%;
    text-transform: capitalize;
  }
  .create {
    width: 50%;
    .table {
      display: flex;
      justify-content: space-between;
      align-items: center;
      input {
        margin: 0.5rem;
      }
    }
    #size {
      width: 3rem;
      height: 2rem;
    }
    button {
      width: 100%;
      margin: 1rem 0;
      background-color: var(--secondary-colour);
    }
  }
  .created {
    width: 50%;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .tables {
      display: flex;
      flex-wrap: wrap;
      .draggable {
        z-index: 1000;
      }
    }
  }
}

.table-layout {
  height: 70vh;
  display: flex;
  /* flex-wrap: wrap; */
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  background-color: var(--primary-colour);
  padding: 1rem;
  margin: 1rem 0;
  border-radius: 20px;
  .head {
    display: flex;
    justify-content: space-between;
    width: 100%;
    h3 {
      text-transform: capitalize;
      opacity: 0.5;
    }
    .zoom-in-out {
      display: flex;
      align-items: center;
      background-color: var(--secondary-colour);
      border-radius: 15px;
      .icon {
        padding: 0.5rem;
        color: var(--action-colour);
      }
      .zoom-btn {
        text-align: center;
        background-color: var(--primary-colour);
        color: var(--action-colour);
        font-size: x-large;
        border-radius: 10px;
        padding: 0.5rem;
        margin: 0.5rem;
        cursor: pointer;
        box-shadow: var(--box-shadow);
      }
    }
  }
  .layout {
    overflow: hidden;
    background-color: var(--secondary-colour);
    border-radius: 15px;
    margin: 0.5rem 0 auto;
    /* padding: 0.5rem; */
    width: 100%;
    height: 100%;
  }
  #layout {
    position: relative;
    overflow: hidden;
    background-color: var(--secondary-colour);
    border-radius: 15px;
    margin: 0.5rem 0 auto;
    padding: 0.5rem;
    width: 100%;
    height: 100%;
  }
}

@media only screen and (orientation: portrait) {
  .floor-plan {
    width: 92%;
  }
  .header {
    flex-direction: column-reverse;
    .spaces {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
    }
  }
  .table {
    justify-content: space-evenly !important;
  }
  .inner-table {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .radio-span {
      width: 100%;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: space-between;
    }
  }
  .size {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .create-table {
    flex-direction: column;
    .create {
      width: 100%;
    }
    .created {
      width: 100%;
    }
  }
  .table-layout {
    .head {
      flex-direction: column;
      .zoom-in-out {
        flex-direction: row;
        .icon {
          padding: 0.5rem;
        }
        .zoom-btn {
          padding: 0.5rem;
        }
      }
    }
  }
  .layout {
    overflow: auto;
    #layout {
      position: relative;
      overflow: auto;
      svg {
        overflow: auto;
      }
    }
  }
  .reset-btn {
    position: fixed;
    color: var(--action-colour);
    font-size: large;
    float: right;
    border-radius: 10px;
    padding: 0.5rem;
    margin: 0.5rem;
    cursor: pointer;
    opacity: 0.5;
  }
}
</style>
