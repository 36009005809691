<template>
  <Authenticated>
    <div class="container">
      <h1>{{ currentSite }} settings</h1>
      <section>
        <h2>Settings</h2>
        <DirectSettings :site="currentSite" @update-settings="update" />
      </section>

      <h2>{{ currentSite }}</h2>
      <section class="button-container">
        <router-link :to="`/menus/${currentSite}`">Manage menu</router-link>
        <router-link v-if="currentSite != 'catering'" :to="`/operatinghours/${currentSite}`">Manage Operating hours</router-link>
        <router-link v-if="floorPlan" :to="`/floorplan/${currentSite}`">Manage floorplan</router-link>
        <div class="select-region">
          <h4 v-if="selectedRegion">Region: {{ selectedRegion }}</h4>
          <h4 v-else>Select a region</h4>
          <select name="region" id="region" v-model="selectedRegion" @change="setRegion()">
            <option value="" selected>Select Region</option>
            <option v-for="region in regions" :key="region" :value="region">{{ region }}</option>
          </select>
        </div>
      </section>
    </div>
  </Authenticated>
</template>

<script>
import { mapGetters } from "vuex";
import DirectSettings from "../Settings/DirectSettings.vue";
import Authenticated from "../_layouts/Authenticated.vue";
import createdMixin from "@/components/_mixins/createdMixin";
import holidays2025 from "../../../public/holidays2025.json";

export default {
  name: "SiteAdjust",
  mixins: [createdMixin],
  components: {
    DirectSettings,
    Authenticated,
  },
  data() {
    return {
      site: this.$route.params.site,
      showModal: false,
      currentSite: this.$route.params.site,
      floorPlan: null,
      regions: [],
      selectedRegion: "",
      currentYear: new Date().getFullYear(),
      lastYear: new Date().getFullYear() - 1,
    };
  },
  methods: {
    async update() {
      await this.$store.dispatch("getSettings");
      this.floorPlan = this.siteSettings[this.currentSite].floor_plan;
    },

    checkRegion() {
      if (this.siteSettings[this.currentSite].hours == null) {
        return;
      }
      let holiday = this.siteSettings[this.currentSite].hours;
      if (holiday["holiday" + this.lastYear] || holiday["holiday"] || holiday == undefined || Object.keys(holiday).length == 0) {
        this.resetHolidayHours();
      } else {
        holiday["holiday" + this.currentYear].forEach((h) => {
          if (h.regional == true) {
            let region = h.name.split(" ").splice(0, h.name.split(" ").length - 2);
            this.selectedRegion = region.join(" ");
          } else {
            this.getRegions();
          }
        });
      }
    },

    resetHolidayHours() {
      let holHours = this.siteSettings[this.currentSite].hours;
      if (holHours["holiday" + this.lastYear]) {
        this.channels.map((channel) => {
          let topic = channel.topic.split(":");
          if (topic[0] == "pos" && topic[2] == this.site) {
            channel.push("company:site:setting", { key: "hours", value: {} });
          }
        });
      }
      this.getRegions();
    },

    getRegions() {
      holidays2025.forEach((holiday) => {
        if (holiday.Type == "Regional") {
          let region = holiday.HolidayName.split(" ").splice(0, holiday.HolidayName.split(" ").length - 2);
          this.regions.push(region.join(" "));
        }
      });
    },

    updateRegionalHoliday() {
      let found = holidays2025.find((h) => h.HolidayName.includes(this.selectedRegion));
      let regHol = {
        name: found.HolidayName,
        date: found.ActualDate,
        open: false,
        closeTime: "00:00",
        openTime: "00:00",
        regional: true,
      };
      this.siteSettings[this.currentSite].hours["holiday" + this.currentYear].push(regHol);
      this.channels.map((channel) => {
      let topic = channel.topic.split(":");
      if (topic[0] == "pos" && topic[2] == this.site) {
      channel.push("company:site:setting", { key: "hours", value: this.siteSettings[this.currentSite].hours });
      }
      });
    },

    setRegion() {
      this.$store.dispatch("setRegion", this.selectedRegion);
      let regionalHol = false;
      this.siteSettings[this.currentSite].hours["holiday" + this.currentYear].forEach((h) => {
        if (h.regional == true) {
          return;
        } else {
          regionalHol = true;
        }
      });
      if (regionalHol) {
        this.updateRegionalHoliday();
      }
    },
  },
  computed: {
    ...mapGetters({
      siteSettings: "siteSettings",
      siteMap: "siteMap",
      channels: "channels",
    }),
  },

  mounted() {
    this.floorPlan = this.siteSettings[this.currentSite].floor_plan;
    this.checkRegion();
  },

  watch: {},
};
</script>

<style scoped lang="scss">
@import "public/stylesheets/wrapper";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2rem 1rem;
  h1 {
    text-transform: capitalize;
  }
}

h2 {
  text-transform: capitalize;
  float: left;
  width: 100%;
}

.button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 1rem 1rem;
  padding: 0.5rem 1rem;
  width: 95%;
  border-radius: 20px;
  background-color: var(--primary-colour);

  a {
    color: var(--text-colour);
    background-color: var(--secondary-colour);
    padding: 2rem;
    margin: 1rem;
    height: 3rem;
    border-radius: 10px;
    box-shadow: var(--box-shadow);
    text-decoration: none;
    font-size: larger;
    text-transform: capitalize;
    align-content: center;
  }

  .select-region {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 1rem;
    padding: 2rem;
    height: 3rem;
    border-radius: 10px;
    background-color: var(--secondary-colour);
    box-shadow: var(--box-shadow);
    h4 {
      margin: 0.5rem;
    }
    select {
      padding: 0.5rem;
      margin: 0.5rem;
      border-radius: 10px;
      box-shadow: var(--box-shadow);
    }
  }
}

@media only screen and (orientation: portrait) {
  .container {
    margin: 0 auto;
    .button-container {
      flex-direction: column;
      margin: auto;
      padding: 1rem;
      width: 80%;
      a,
      .select-region {
        width: 75%;
      }
    }
  }
}
</style>
