<template>
  <Authenticated>
    <h1>Manage {{ site }} menus</h1>
    <div class="spacer"></div>
    <div class="tab-buttons">
      <button class="tab-btn" data-tab="products" @click.stop.prevent="showProductTab">
        Products
      </button>
      <button class="tab-btn" data-tab="modifiers" @click.stop.prevent="showModifierTab">
        Modifiers
      </button>
    </div>
    <section class="tab-container">
      <section v-if="modifierTab" class="tab">
        <h2>Modifiers: {{ site }}</h2>
        <div class="section">
          <div tabindex="0" v-for="(modifierset, idx) in modifiersets" :key="modifierset + idx" class="product-grid-container">
            <div v-if="modifierset.value.rules" class="bin">
              <h3 class="bin-header">
                {{ modifierset.value.name }}
                <font-awesome-icon icon="trash" @click.stop.prevent="check(idx, modifierset, 'modifier')" size="xl" />
              </h3>
              <span class="rules">
                <p>Inventory</p>
                <ToggleSwitch
                  :modelName="modifierset.value.name + 'inventory'"
                  :modelValue="modifierset.value.rules.inventory"
                  @update:modelValue="saveMod($event, modifierset, 'inventory')"
                />
              </span>
              <span class="rules">
                <p>Limit one</p>
                <ToggleSwitch
                  :modelName="modifierset.value.name + 'limit'"
                  v-model="modifierset.value.rules.oneonly"
                  @update:modelValue="saveMod($event, modifierset, 'oneonly')"
                />
              </span>
              <span class="rules">
                <p>Required</p>
                <ToggleSwitch
                  :modelName="modifierset.value.name + 'required'"
                  v-model="modifierset.value.rules.required"
                  @update:modelValue="saveMod($event, modifierset, 'required')"
                />
              </span>
            </div>
            <div class="product-grid">
              <div
                :tabindex="idx"
                v-for="(modifier, idx) in modifierset.value.modifiers"
                :key="modifier"
                class="product"
                @click.stop.prevent="openModifierModal(modifier, modifierset)"
              >
                <p>{{ modifier.name }}</p>
              </div>
              <div class="product" @click.stop.prevent="openModifierModal('addNew', modifierset)">
                <p>Add New Modifier</p>
              </div>
            </div>
          </div>
          <div class="product add-new" @click.stop.prevent="add('modifierSet')">
            <p>Add New Modifier Set</p>
          </div>
        </div>
      </section>
      <section v-if="productTab" class="section tab">
        <h2>Products: {{ site }}</h2>
        <div v-for="(category, idx) in POSCategories(site)" :key="category" class="product-grid-container">
          <h3 class="bin-header">
            {{ category.value.name }}
            <font-awesome-icon icon="trash" @click.stop.prevent="check(idx, category, 'category')" size="xl" />
          </h3>

          <div class="product-grid">
            <div tabindex="0" v-for="product in category.value.products" :key="product" class="product" @click.stop.prevent="openModal(product, category.value.name)">
              <p class="none">
                {{ (prod = POSProductLookup({ site: site, prod: product })) }}
              </p>
              <p v-if="prod">{{ prod.value.name }}</p>
              <p v-if="prod">${{ prod.value.price }}</p>
            </div>
            <div class="product" @click.stop.prevent="openModal('addNew', category.value.name)">
              Add New Product
            </div>
          </div>
        </div>
        <h3>Add new Product Category</h3>
        <div class="product-grid">
          <div class="product" @click.stop.prevent="add('category')">
            <p>Add New Product category</p>
          </div>
        </div>
      </section>
    </section>
    <section v-if="newModSet || newCategory" class="check-container">
      <div class="check-content">
        <div class="set-name">
          <div v-if="labelInput.value == 'mod'">
            <label for="name"
              ><h3>{{ labelInput.label }}:</h3></label
            >
            <input type="text" v-model="newModSetName" />
            <div class="btns">
              <button @click.stop.prevent="addNewModifierSet">Create</button>
              <button @click.stop.prevent="newModSet = !newModSet">
                Cancel
              </button>
            </div>
          </div>
          <div v-if="labelInput.value == 'cat'">
            <label for="name"
              ><h3>{{ labelInput.label }}:</h3></label
            >
            <input type="text" v-model="newCategoryName" />
            <div class="btns">
              <button @click.stop.prevent="addCategory">Create</button>
              <button @click.stop.prevent="newCategory = !newCategory">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section v-if="deleteCheck" class="check-container">
      <div class="check-content">
        <h3>Are you sure you want to delete this {{ type }}?</h3>
        <div class="btns">
          <button @click.stop.prevent="confirmDelete(type)">Delete</button>
          <button @click.stop.prevent="deleteCheck = !deleteCheck">
            Cancel
          </button>
        </div>
      </div>
    </section>
    <ModifierModal
      :modifier="selectedModifier"
      :modifierset="currentModSet"
      :open="showModifierModal"
      @close-modal="showModifierTab"
      @commit="saveModifier"
      @remove="removeModifier"
    />
    <ProductModal :product="product.value" :open="showModal" :modifierSets="modifiersets" @save="saveProduct" @remove="removeProduct" @close-modal="showProductTab" />
  </Authenticated>
</template>

<script>
import { mapGetters } from "vuex";
import Authenticated from "../_layouts/Authenticated.vue";
import createdMixin from "@/components/_mixins/createdMixin";
import ProductModal from "@/components/Menus/ProductModal";
import ModifierModal from "@/components/Menus/ModifierModal.vue";
import ToggleSwitch from "@/components/_layouts/ToggleSwitch.vue";
import { api } from "@/helpers/api";
import Slug from "slug";

export default {
  name: "SiteMenu",
  mixins: [createdMixin],
  components: { ProductModal, ModifierModal, ToggleSwitch, Authenticated },

  data() {
    return {
      site: this.$route.params.site,
      modifierTab: false,
      productTab: true,
      savedTab: this.$route.params.savedTab,
      categories: [],
      product: {},
      showModal: false,
      showModifierModal: false,
      company: localStorage.getItem("company"),
      modifiersets: false,
      channel: false,
      selectedModifier: {},
      newModSet: false,
      newCategory: false,
      newCategoryName: "",
      newModSetName: "",
      deleteCheck: false,
      current: {},
      currentModSet: {},
      type: "",
      idx: "",
      labelInput: {
        value: "",
        label: "",
      },
    };
  },

  async mounted() {
    if (this.savedTab === "modifiers") {
      this.showModifierTab();
    } else {
      this.showProductTab(); // Default to Products tab if nothing is saved
    }

    this.modifiersets = await api.getModifierSets(this.company, this.site);

    this.channels.map((channel) => {
      let topic = channel.topic.split(":");
      if (topic[0] == "pos" && topic[2] == this.site) {
        this.channel = channel;
      }
    });
  },

  computed: {
    ...mapGetters({
      POSProducts: "POSProducts",
      POSCategories: "POSCategories",
      POSProductLookup: "POSProductLookup",
      channels: "channels",
    }),
  },
  methods: {
    saveMod(val, mod, rule) {
      mod.value.rules[rule] = val;
      this.save(mod.value);
    },

    save(doc) {
      this.channel.push("document:update", doc);
    },

    add(type) {
      if (type == "modifierSet") {
        this.labelInput = {
          value: "mod",
          label: "Modifier Set Name",
        };
        this.newModSet = true;
        return;
      }
      if (type == "category") {
        this.labelInput = {
          value: "cat",
          label: "Category Name",
        };
        this.newCategory = true;
        return;
      }
    },

    async removeCategory() {
      await this.channel.push("document:delete", {
        doc: this.current.key._id,
        rev: this.current.key._rev,
      });
      this.$store.dispatch("getPOSCategories");
      this.deleteCheck = false;
    },

    async addCategory() {
      let cat = {
        _id: "category:" + Slug(this.newCategoryName),
        name: this.newCategoryName,
        description: "",
        posOnly: false,
        products: [],
      };
      this.newCategory = false;
      await this.channel.push("document:new", cat);
      this.$store.dispatch("getPOSCategories");
    },

    removeModifier() {
      let modIndex = this.currentModSet.value.modifiers.findIndex((m) => m.name == this.selectedModifier.name);
      this.currentModSet.value.modifiers.splice(modIndex, 1);
      this.channel.push("document:update", this.currentModSet.value);
    },

    //not saving new modifiers
    async saveModifier() {
      let found = false;
      if (this.currentModSet.value.modifiers.length == 0) {
        console.log("empty");
        this.currentModSet.value.modifiers.push(this.selectedModifier);
      }
      this.currentModSet.value.modifiers.forEach((mod) => {
        if (mod.name == this.selectedModifier.name) {
          found = true;
        }
      });
      if (!found) {
        this.currentModSet.value.modifiers.push(this.selectedModifier);
        await this.channel.push("document:update", this.currentModSet.value);
      } else {
        await this.channel.push("document:update", this.currentModSet.value);
      }
    },

    check(idx, current, type) {
      this.deleteCheck = !this.deleteCheck;
      this.type = type;
      this.idx = idx;
      this.current = current;
    },

    confirmDelete(type) {
      if (type == "modifier") {
        this.removeModifierSet();
      }
      // this.confirmDelete = false;
      if (type == "category") {
        this.removeCategory();
      }
    },

    async removeModifierSet() {
      await this.channel.push("document:delete", {
        doc: this.current.value._id,
        rev: this.current.value._rev,
      });
      this.modifiersets = await api.getModifierSets(this.company, this.site);
      this.deleteCheck = false;
    },

    async addNewModifierSet() {
      let modSet = {
        _id: "modifierset:" + Slug(this.newModSetName),
        name: this.newModSetName,
        modifiers: [],
        rules: { inventory: false, oneonly: false, required: false },
      };
      this.channel.push("document:new", modSet);
      this.modifiersets = await api.getModifierSets(this.company, this.site);
      this.newModSet = false;
    },

    openModifierModal(modifier, modSet) {
      this.currentModSet = modSet;
      if (modifier == "addNew") {
        this.selectedModifier = {
          active: false,
          desc: "",
          name: "",
          price: 0,
          quantity: 0,
        };
        this.showModifierModal = !this.showModifierModal;
        return;
      } else {
        this.selectedModifier = modifier;
        this.currentModSet = modSet;
        this.showModifierModal = !this.showModifierModal;
      }
    },

    showModifierTab() {
      this.modifierTab = true;
      this.productTab = false;
      this.tab = "modifiers";
      this.$router.push({ params: { savedTab: "modifiers" } });

      document.querySelectorAll(".tab-btn").forEach((btn) => {
        btn.classList.remove("active"); // Clear the active class from all buttons
      });

      //keeps focus on the tab button
      this.$nextTick(() => {
        let mod_btn = document.querySelector('.tab-btn[data-tab="modifiers"]');
        mod_btn.classList.add("active");
      });
    },

    showProductTab() {
      this.productTab = true;
      this.modifierTab = false;
      this.tab = "products";
      this.$router.push({ params: { savedTab: "products" } });

      document.querySelectorAll(".tab-btn").forEach((btn) => {
        btn.classList.remove("active"); // Clear the active class from all buttons
      });

      //keeps focus on the tab button
      this.$nextTick(() => {
        let prod_btn = document.querySelector('.tab-btn[data-tab="products"]');
        prod_btn.classList.add("active");
      });
    },

    async removeProduct(product) {
      let prodCat = "category:" + product.category;
      let foundCategory = this.POSCategories(this.site).find((cat) => {
        return cat.id === prodCat ? cat : null;
      });

      var foundProductIndex = foundCategory.value.products.findIndex((p) => {
        let prod = product._id.split(":")[1];
        return p === prod ? p : null;
      });

      if (foundProductIndex != -1) {
        await this.channel.push("document:delete", {
          doc: product._id,
          rev: product._rev,
          category: Slug(product.category),
        });
        this.$store.dispatch("getAllData");
      }
    },

    saveProduct(product) {
      var foundProduct = this.POSProductLookup({
        site: this.site,
        prod: Slug(product.name),
      });
      if (foundProduct) {
        foundProduct = product;
        this.channel.push("document:update", product);
      } else {
        product._id = "product:" + Slug(product.name);
        product.category = Slug(product.category);
        this.channel.push("document:new", product);
        this.$store.dispatch("getAllData");
      }
    },

    openModal(product, catergory) {
      if (product == "addNew") {
        this.product = {
          value: {
            name: null,
            description: null,
            caption: null,
            active: true,
            price: null,
            modifiersets: [],
            images: [],
            soldout: false,
            category: catergory,
          },
        };
        this.showModal = !this.showModal;
        return;
      } else {
        this.showModal = !this.showModal;
        this.product = this.POSProductLookup({
          site: this.site,
          prod: product,
        });
      }
    },
  },

  watch: {
    tab: function(newTab) {
      this.$router.replace({
        name: "site-menu-tab",
        params: { site: this.site, tab: newTab },
      });
    },
  },
};
</script>

<style scoped>
.none {
  display: none;
}

h1 {
  margin: 1rem;
  text-transform: capitalize;
}

.expand {
  display: none;
}

.details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  z-index: 100;
  position: relative;
  background-color: var(--secondary-colour);
  border-radius: 10px;
  box-shadow: var(--box-shadow);
  .blue {
    color: var(--action-colour);
    cursor: pointer;
    margin: 1rem;
  }
}

.check-container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: var(--background-fade);
}
.check-content {
  position: relative;
  top: 30%;
  left: 30%;
  height: 30vh;
  width: 40vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1rem;
  z-index: 100;
  border-radius: 10px;
  box-shadow: var(--box-shadow);
  background-color: var(--secondary-colour);
  .set-name {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 1rem;
    width: 90%;
    input {
      margin: 0.5rem;
      padding: 0.5rem;
      border-radius: 10px;
      border: none;
      box-shadow: var(--box-shadow);
      width: 90%;
    }
    .btns {
      display: flex;
      flex-direction: row;
      button {
        margin: 0.5rem;
        padding: 1rem 2rem;
        border-radius: 10px;
        box-shadow: var(--box-shadow);
        background-color: var(--action-colour);
        color: var(--text-colour);
        &:hover {
          cursor: pointer;
          background-color: var(--action-colour);
        }
      }
    }
  }
}

.section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-y: scroll;
  height: 100%;
}

h2 {
  margin: 1rem 0.5rem 0.5rem 1rem;
  text-transform: capitalize;
}

svg {
  margin: 0 0.5rem;
  color: var(--action-colour);
  cursor: pointer;
}

.product-grid-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 0.5rem;
  .bin {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 95%;
    margin: 2rem;
    h3 {
      flex-grow: 1;
    }
    .rules {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 1rem;
      p {
        margin: 0 0.5rem;
      }
    }
  }
}

.product-grid {
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(7, 1fr);
  gap: 0.5rem;
  margin: 0.5rem;
  padding: 1rem;
}

.add-new {
  margin: 1rem 1.5rem;
  font-size: large;
}
.product {
  height: 5rem;
  width: 10rem;
  border-radius: 5px;
  padding: 0.5rem;
  background-color: var(--secondary-colour);
  box-shadow: var(--box-shadow);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  cursor: pointer;
  p {
    margin: 0.25rem;
    color: var(--text-colour);
  }
  &:active {
    box-shadow: none;
  }
}

.tab-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-colour);
  border-radius: 20px;
  z-index: 1;
  box-shadow: var(--box-shadow);
  margin: 0 1rem;
}

.tab-buttons {
  display: flex;
  flex-direction: row;
  margin: 0 0 0 2rem;
}
.tab-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10rem;
  height: 2.5rem;
  margin: 0.5rem 0 0 0;
  padding: 1rem;
  background-color: var(--primary-colour);
  opacity: 0.5;
  color: var(--text-colour);
  border-radius: 10px 10px 0 0;
  text-transform: capitalize;
  transition: color 300ms ease-in-out;
  box-shadow: none;
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    color: var(--action-colour);
  }
}

.tab-btn.active {
  /* z-index: 10; */
  box-shadow: 0px -5px 5px rgb(0, 0, 0, 0.2);
  transform: translate3d(0, 5px, 1px);
  opacity: 1;
}

@media only screen and (orientation: portrait) {
  h3,
  h2 {
    width: 50%;
  }

  .product-grid-container {
    width: 100%;

    .bin {
      width: 80%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .bin-header {
      width: 80%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    .product-grid {
      width: 50%;
      grid-template-columns: repeat(2, 0fr);
      grid-auto-flow: row;
      margin: 0.5rem;
    }
  }
  .product {
    width: 8rem;
    height: 3.5rem;
  }

  .spacer {
    height: 4rem;
  }

  .tab-buttons {
    margin: 0 0 0 1.75rem;
  }

  .tab-container {
    margin: 0 0.5rem;
    border-radius: 20px;
  }
}
</style>
