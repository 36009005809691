<template>
  <Authenticated>
    <div class="container">
      <section class="section">
        <h2>Websites</h2>
        <div class="create-website">
          <h3>Create new Website</h3>
          <select name="site" id="" v-model="site">
            <option value="" selected>select a site</option>
            <option v-for="site in sites" :key="site">{{ site }}</option>
          </select>
          <span><input type="text" v-model="websiteName" placeholder="Select site or create your own" />.web.beamy.nz</span>
          <button @click.stop.prevent="createNewSite">Create new website</button>
        </div>
        <ul class="websites">
          <li v-for="site in websites" :key="site">
            <div class="column">
              <p>Location - {{ site.site }}</p>
              <a :href="'https://' + site.hash + '.web.beamy.nz'">URL: {{ site.hash }}.web.beamy.nz</a>
            </div>
            <font-awesome-icon icon="fa-solid fa-trash" class="bin-icon" size="xl" @click.stop.prevent="removeSite(site)" />
          </li>
        </ul>
      </section>
      <section class="section">
        <h2>Customers</h2>
        <button @click.stop.prevent="changeSortBy">
          <i>Sort By {{ listOrder }}</i>
        </button>
        <div class="pag-btns">
          <button @click.stop.prevent="getUserOrders(pagCounter(false))">-</button>

          <button @click.stop.prevent="getUserOrders(pagCounter(true))">+</button>
        </div>
        <ul class="users">
          <li v-for="user in users" :key="user.id" @click.stop.prevent="showUserDetails(user)">
            <h3>{{ user.value.fname }} {{ user.value.lname }}</h3>
            <p>No. orders: {{ user.key }}</p>
          </li>
        </ul>
        <UserOrderModal :open="open" :user="user" @close-modal="closeModal" />
      </section>
    </div>
  </Authenticated>
</template>

<script>
import Authenticated from "../_layouts/Authenticated.vue";
import { mapGetters } from "vuex";
import { api } from "@/helpers/api";
import UserOrderModal from "./UserOrderModal.vue";
import createdMixin from "../_mixins/createdMixin.js";

export default {
  name: "WebPage",
  mixins: [createdMixin],

  components: {
    Authenticated,
    UserOrderModal,
  },
  data() {
    return {
      listOrder: "descending",
      users: [],
      sortBy: true,
      currentPag: 0,
      user: {},
      open: false,
      site: "",
      websiteName: null,
    };
  },
  computed: {
    ...mapGetters({
      channels: "channels",
      companyName: "companyName",
      websites: "websites",
      sites: "sites",
      companySettings: "companySettings",
      siteSettings: "siteSettings",
    }),
    // webChannels() {
    //   let topics = [];
    //   this.channels.map((chan) => (chan.topic.split(":")[0] == "web") ? topics.push(chan.topic) : false);
    //   return topics;
    // },
  },
  mounted() {
    this.getUserOrders(0);
  },

  methods: {
    // circularReplacer() {
    //   const seen = new WeakSet(); // object
    //   return (key, value) => {
    //     if (typeof value === "object" && value !== null) {
    //       if (seen.has(value)) {
    //         return;
    //       }
    //       seen.add(value);
    //     }
    //     return value;
    //   };
    // },

    createNewSite() {
      console.log(this.websites);
      this.websites.push({ hash: this.websiteName, site: this.site });
      api.save_company_settings(this.companyName, "website", this.websites);
    },

    removeSite(site) {
      let websites = this.websites.filter((s) => s.hash != site.hash);
      this.$store.dispatch("setWebsites", websites);
    },

    changeSortBy() {
      this.sortBy = !this.sortBy;
      this.getUserOrders(0);
      this.listOrder = this.sortBy ? "descending" : "ascending";
    },

    closeModal() {
      this.open = false;
    },

    showUserDetails(user) {
      this.user = user;
      this.open = true;
    },

    pagCounter(next) {
      if (next) {
        this.currentPag += 10;
      } else {
        this.currentPag -= 10;
      }
      return this.currentPag;
    },

    async getUserOrders(pagination) {
      let result = await api.get_enduser_orders(this.companyName, this.sortBy, 10, pagination);
      this.users = result.rows;
    },
  },

  watch: {
    site: {
      handler() {
        this.websiteName = this.companyName + "-" + this.site;
      },
      immediate: true,
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  margin: 1.5rem;
}
.container {
  display: flex;
  align-items: flex-start;
  margin: 0.5rem 1rem;

  .section {
    margin: 0.5rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    max-width: 90%;
    min-width: 40%;
    min-height: 85vh;
    height: 100%;
    padding: 0.5rem;
    border-radius: 10px;
    background: var(--primary-colour);
    box-shadow: var(--box-shadow);
    .create-website {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0.5rem;
      padding: 1rem;
      border-radius: 10px;
      background-color: var(--secondary-colour);
      box-shadow: var(--box-shadow);
      select {
        width: 100%;
        margin: 0.5rem;
        padding: 0.5rem;
        border-radius: 5px;
        border: none;
        color: var(--text-colour);
        background-color: var(--primary-colour);
        box-shadow: var(--box-shadow);
      }
      span {
        margin: 0.5rem;
        padding: 0.5rem;
        border-radius: 5px;
        border: none;
        text-wrap: auto;
        color: var(--action-colour);
        font-size: large;
        margin: 0;
        input {
          width: 15rem;
          margin: 0.5rem;
          padding: 0.5rem;
          border-radius: 5px;
          border: none;
          color: var(--text-colour);
          background-color: var(--primary-colour);
          box-shadow: var(--box-shadow);
        }
      }
      button {
        width: 100%;
        margin: 0.5rem;
        padding: 0.5rem;
        background-color: var(--action-colour);
        color: var(--secondary-text-colour);
        border-radius: 5px;
        border: none;
        box-shadow: var(--box-shadow);
        cursor: pointer;
        &:active {
          box-shadow: none;
        }
      }
    }
    .websites {
      padding-left: 0;
      max-width: 90%;
      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        list-style: none;
        margin: 0.5rem;
        background-color: var(--secondary-colour);
        box-shadow: var(--box-shadow);
        padding: 1rem;
        border-radius: 10px;
        .bin-icon {
          color: var(--action-colour);
          padding: 0.5rem;
          cursor: pointer;
        }
        .column {
          display: flex;
          flex-direction: column;
          p,
          a {
            margin: 0.5rem;
          }
          a {
            color: var(--action-colour);
            cursor: pointer;
          }
        }
      }
    }
    .pag-btns {
      button {
        max-width: 5rem;
        min-width: 3rem;
        background-color: var(--action-colour);
        color: var(--secondary-text-colour);
        text-transform: capitalize;
        border-radius: 10px;
        font-size: 1.5rem;
        i {
          width: 100%;
          margin: 0.25rem;
          font-size: 0.75rem;
        }
      }
    }
    .users {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-evenly;
      padding: 0.25rem;
      li {
        width: 15rem;
        list-style: none;
        margin: 0.5rem;
        padding: 1rem;
        background-color: var(--secondary-colour);
        box-shadow: var(--box-shadow);
        border-radius: 10px;
        cursor: pointer;
        p {
          margin: 0.5rem;
        }
        h3 {
          margin: 0.5rem;
          color: var(--action-colour);
        }
      }
    }
  }
}

@media (orientation: portrait) {
  .container {
    margin: 0;
    .section {
      align-items: center;
      border-radius: 0;
      margin: 1rem 0;
      max-width: none;
      h2 {
        margin: 1rem 0;
      }
    }

    flex-direction: column;
  }
}
</style>
