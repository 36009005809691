<template>
  <div class="day-container">
    <ToggleSwitch onLabel="Open" offLabel="Closed" :modelValue="openClose" :modelName="modelName" @update:modelValue="setOpen($event)" />
    <div v-if="openClose" class="time-input">
      <span><input type="time" v-model="localOpenTime"/> <input type="time" v-model="localCloseTime"/></span>
    </div>
  </div>
</template>

<script>
import ToggleSwitch from "@/components/_layouts/ToggleSwitch.vue";
export default {
  name: "DayOperatingHours",
  props: ["open", "openTime", "closeTime", "modelName"],
  emit: ["setOpenTime"],
  components: {
    ToggleSwitch,
  },
  data() {
    return {
      openClose: this.open,
      localOpenTime: this.openTime,
      localCloseTime: this.closeTime,
    };
  },
  methods: {
    setOpen(value) {
      this.openClose = value;
      this.$emit("setOpen", value);
    },
  },
  mounted() {},
  watch: {
    localOpenTime() {
      this.$emit("setOpenTime", this.localOpenTime);
    },
    localCloseTime() {
      this.$emit("setCloseTime", this.localCloseTime);
    },
    openClose() {
      this.$emit("setOpen", this.openClose);
    },
    open() {
      this.openClose = this.open;
    },
    openTime() {
      this.localOpenTime = this.openTime;
    },
    closeTime() {
      this.localCloseTime = this.closeTime;
    },
  },
};
</script>

<style lang="scss" scoped>
.day-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 0.5rem 0;
  border-radius: 10px;
  div {
    // margin: 0 5px;
    span {
      display: flex;
      flex-direction: column;
      input {
        width: 100px;
        height: 30px;
        border-radius: 5px;
        border: 1px solid var(--action-colour);
        padding: 0 5px;
        margin: 0 5px;
        background-color: var(--banner-colour);
        color: var(--alt-text-colour);
      }
    }
  }
}
@media only screen and (orientation: portrait) {
  .day-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    div {
      span {
        display: flex;
        flex-direction: column;
        align-items: center;
        input {
          margin: 3px 0;
        }
      }
    }
  }
}
</style>
