<template>
  <Authenticated>
    <div class="hours-header">
      <h1>{{ site }} Operating Hours</h1>
      <button @click.stop.prevent="saveOpeningHours">Save Opening Hours</button>
    </div>
    <div class="hours-container">
      <ul>
        <div class="ul-header">
          <h2>Opening Hours</h2>
        </div>
        <button v-if="!setAllDays" @click.stop.prevent="showSetAll('week')" class="set-btn">Set all days</button>
        <div v-if="setAllDays">
          <DayOperatingHours
            modelName="weekdays"
            :open="daySetOpen"
            :openTime="daySetOpenTime"
            :closeTime="daySetCloseTime"
            @setOpenTime="daySetOpenTime = $event"
            @setCloseTime="daySetCloseTime = $event"
            @setOpen="daySetOpen = $event"
          />
          <button @click.stop.prevent="updateDays" class="set-btn">Save</button>
        </div>
        <li v-for="day in weekFinal" :key="day.name">
          <div class="days"
            ><span>{{ day.name }}</span>
            <DayOperatingHours
              :modelName="day.name"
              :open="day.open"
              :openTime="day.openTime"
              :closeTime="day.closeTime"
              @setOpenTime="day.openTime = $event"
              @setCloseTime="day.closeTime = $event"
              @setOpen="day.open = $event"
            />
        </div>
        </li>
      </ul>
      <ul>
        <div class="ul-header">
          <button v-if="currentYear == nextYear" @click.stop.prevent="updateYear(thisYear)"><font-awesome-icon icon="fa-arrow-left" /></button>
          <h2>{{ currentYear }} Public Holidays</h2>
          <button v-if="currentYear == thisYear" @click.stop.prevent="updateYear(nextYear)"><font-awesome-icon icon="fa-arrow-right" /></button>
        </div>
        <button v-if="!setAllHolidays" @click.stop.prevent="showSetAll('holiday')" class="set-btn">Set all Holidays</button>
        <div v-if="setAllHolidays">
          <DayOperatingHours
            v-if="setAllHolidays"
            modelName="holidays"
            :open="holidaySetOpen"
            :openTime="holidaySetOpenTime"
            :closeTime="holidaySetCloseTime"
            @setOpenTime="holidaySetOpenTime = $event"
            @setCloseTime="holidaySetCloseTime = $event"
            @setOpen="holidaySetOpen = $event"
          />
          <button @click.stop.prevent="updateHolidayDays" class="set-btn">Save</button>
        </div>
        <li v-for="holiday in holidayFinal" :key="holiday">
          <div v-if="holiday.name != ''" class="days"
            ><span>{{ holiday.name }} {{ holiday.date }}</span>
            <DayOperatingHours
              :modelName="holiday.name"
              :open="holiday.open"
              :openTime="holiday.openTime"
              :closeTime="holiday.closeTime"
              @setOpenTime="holiday.openTime = $event"
              @setCloseTime="holiday.closeTime = $event"
              @setOpen="holiday.open = $event"
            />
        </div>
        </li>
      </ul>
    </div>
  </Authenticated>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import DayOperatingHours from "./DayOperatingHours.vue";
import Authenticated from "../_layouts/Authenticated.vue";
import holidays2025 from "../../../public/holidays2025.json";
import holidays2026 from "../../../public/holidays2026.json";
import createdMixin from "@/components/_mixins/createdMixin";

export default {
  components: {
    DayOperatingHours,
    Authenticated,
  },
  mixins: [createdMixin],
  name: "OperatingHoursModal",
  data() {
    return {
      site: this.$route.params.site,
      thisYear: new Date(Date.now()).getFullYear(),
      nextYear: new Date(Date.now()).getFullYear() + 1,
      currentYear: new Date(Date.now()).getFullYear(),
      orderStatus: "In Progress",
      daySetOpen: false,
      daySetOpenTime: "08:30",
      daySetCloseTime: "14:30",
      holidayDays: {},
      weekDays: {},
      holidaySetOpen: false,
      holidaySetOpenTime: "08:30",
      holidaySetCloseTime: "14:30",
      holidayFinal: [],
      holidays: [],
      weekFinal: [],
      daysOfWeek: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
      setAllDays: false,
      setAllHolidays: false,
    };
  },

  methods: {
    ...mapActions(["updateSettings"]),

    showSetAll(type) {
      if (type == "week") {
        this.setAllDays = !this.setAllDays;
      } else {
        this.setAllHolidays = !this.setAllHolidays;
      }
    },

    updateDays() {
      this.weekFinal.forEach((day) => {
        day.open = this.daySetOpen;
        day.openTime = this.daySetOpenTime;
        day.closeTime = this.daySetCloseTime;
      });
      this.setAllDays = false;
    },

    updateYear(year) {
      this.setYear(year);
      let sSettings = this.siteSettings[this.site];
      if ("hours" in sSettings && "holiday" + this.currentYear in sSettings.hours) {
        this.holidayFinal = this.siteSettings[this.site].hours["holiday" + this.currentYear];
      } else {
        this.holidayFinal = this.holidayObj;
      }
    },

    updateHolidayDays() {
      this.holidayFinal.forEach((day) => {
        day.open = this.holidaySetOpen;
        day.openTime = this.holidaySetOpenTime;
        day.closeTime = this.holidaySetCloseTime;
      });
      this.setAllHolidays = false;
    },

    setYear(year) {
      if (this.thisYear.toString() == year.toString()) {
        this.holidays = holidays2025;
      } else if (this.nextYear.toString() == year.toString()) {
        this.holidays = holidays2026;
      }
      this.currentYear = year;
    },

    async saveOpeningHours() {
      let openingHours = {
        site: this.site,
        hours: {
          [`holiday${this.currentYear}`]: this.holidayFinal,
          week: this.weekFinal,
        },
      };
      this.$store.dispatch("setOpeningHours", openingHours);
      this.channels.map((channel) => {
        let topic = channel.topic.split(":");
        if (topic[0] == "pos" && topic[2] == this.site) {
          channel.push("company:site:setting", { key: "hours", value: openingHours.hours });
        }
      });
      this.$router.push(`/settings/${this.site}`);
    },
  },

  computed: {
    ...mapGetters({
      futurePickup: "futurePickup",
      siteSettings: "siteSettings",
      getWebOrder: "getWebOrder",
      companySettings: "companySettings",
      channels: "channels",
      region: "region",
    }),

    regionalHoliday() {
      return this.holidays.filter((hol) => hol.Type == "Regional" && hol.HolidayName.includes(this.region));
    },

    natHolidays() {
      const nats = this.holidays.filter((hol) => hol.Type == "National");
      nats.push(new Proxy({ HolidayName: "", ActualDate: "", Regional: true }, {}));
      return nats;
    },

    formattedDate() {
      const date = new Date(this.getWebOrder.order.pickup_date);
      return date.toDateString();
    },

    holidayObj() {
      let holidays = this.natHolidays.map((day) => {
        const dayOpen = {
          open: false,
          openTime: "",
          closeTime: "",
          name: "",
          date: "",
          regional: false,
        };
        dayOpen.name = day.HolidayName;
        dayOpen.date = day.ActualDate;
        dayOpen.regional = day.Regional;
        return dayOpen;
      });

      if (this.region) {
        const regionalHols = this.regionalHoliday.map((day) => {
          const dayOpen = {
            open: false,
            openTime: "",
            closeTime: "",
            name: "",
            date: "",
            regional: true,
          };
          dayOpen.name = day.HolidayName;
          dayOpen.date = day.ActualDate;
          dayOpen.regional = true;
          return dayOpen;
        });

        holidays = [...holidays, ...regionalHols];
      }
      return holidays;
    },

    weekObj() {
      return this.daysOfWeek.map((day) => {
        const dayOpen = { open: false, openTime: "", closeTime: "", name: "" };
        dayOpen.name = day;
        return dayOpen;
      });
    },
  },

  mounted() {
    this.setYear(this.thisYear);
    let sSettings = this.siteSettings[this.site];
    if ("hours" in sSettings && "holiday" + this.currentYear in sSettings.hours) {
      this.holidayFinal = this.siteSettings[this.site].hours["holiday" + this.currentYear];
    } else {
      this.holidayFinal = this.holidayObj;
    }
    if ("hours" in sSettings && "week" in sSettings.hours) {
      this.weekFinal = this.siteSettings[this.site].hours.week;
    } else {
      this.weekFinal = this.weekObj;
    }
  },

  watch: {
    region() {
      let sSettings = this.siteSettings[this.site];
      if (!("hours" in sSettings && "holiday" + this.currentYear in sSettings.hours)) {
        this.holidayFinal = this.holidayObj;
      }
    },
  },
};
</script>

<style lang="scss">
.hours-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 2rem;
  text-transform: capitalize;
}
.hours-container {
  width: 95%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
  background-color: var(--primary-colour);
  margin: 1rem auto;
  border-radius: 20px;
  box-shadow: var(--box-shadow);

  ul {
    padding: 1rem;
    margin: 1rem;
    .ul-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      button {
        padding: 0.5rem 1rem;
        margin: 0 0.5rem;
        background-color: var(--secondary-colour);
        box-shadow: var(--box-shadow);
      }
      h2 {
        min-width: 4rem;
        text-wrap: nowrap;
      }
    }

    div {
      button {
        padding: 0 1rem;
        margin: 0.5rem;
      }
    }
    .set-btn {
      margin-left: 0;
      background-color: var(--secondary-colour);
    }
    li {
      list-style: none;
      margin: 0.5rem;
      .days {
        span {
          width: 150px;
        }
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
    }
  }
}

@media only screen and (orientation: portrait) {
  .hours-header {
    flex-direction: column;
    align-items: center;
    h1 {
      margin: 1rem;
      text-align: center;
    }
    button {
      margin: 1rem;
    }
  }
  .hours-container {
    flex-direction: column;
    ul {
      display: flex;
      flex-direction: column;
      .year-change {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 30%;
        h2 {
          margin: 5px;
          min-width: 4rem;
        }
      }
      li {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        .days {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          span {
            width: 100%;
            margin: 0.5rem auto;
          }
        }
      }
    }
  }
}
</style>
