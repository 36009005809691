<template>
  <div>
    <section class="container">
      <h1>Billing</h1>
      <button class="press" @click.stop.prevent="openPaymentMethodModal">Add Payment Method</button>
      <div class="card">
        <div v-for="(site, idx) in siteMap" :key="site + idx" class="box">
          <h3 class="title">{{ site.site }}</h3>
          <p class="blue" v-if="site.stations.kds.length">KDS: {{ site.stations.kds.length }}</p>
          <p class="blue" v-else>KDS: 0</p>
          <p class="blue" v-if="site.stations.pos.length">POS: {{ site.stations.pos.length }}</p>
          <p class="blue" v-else>POS: 0</p>
          <p class="blue">Total Stations: {{ site.stations.kds.length + site.stations.pos.length }}</p>
          <div>
            <h3 v-if="site.stations.kds.length + site.stations.pos.length <= 3 && site.stations.kds.length + site.stations.pos.length >= 1">Small POS at ${{ omega }} a month.</h3>
            <h3 v-if="site.stations.kds.length + site.stations.pos.length <= 6 && site.stations.kds.length + site.stations.pos.length > 3">Medium POS at ${{ beta }} a month.</h3>
            <h3 v-if="site.stations.kds.length + site.stations.pos.length > 9">Large POS at ${{ alpha }} a month.</h3>
            <h3 v-else-if="site.stations.kds.length + site.stations.pos.length == 0">This site has no stations yet...</h3>
          </div>
        </div>
        <div class="box">
          <h3>Company wide</h3>
          <p class="blue">Total Stations: {{ totalStations }}</p>
          <h3 v-if="!cogsEnabled" class="blue">Monthly Total: ${{ monthlyTotal }}</h3>
          <p v-if="cogsEnabled" class="blue">Cost of goods: ${{ cogs }}</p>
          <h3 v-if="cogsEnabled">Monthly Total: ${{ monthlyTotal + cogs }}</h3>
        </div>
      </div>
    </section>
  </div>
  <PaymentMethodModal :open="showPaymentModal" />
</template>

<script>
import PaymentMethodModal from "./PaymentMethodModal.vue";
import createdMixin from "@/components/_mixins/createdMixin";
import { mapGetters } from "vuex";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import dayOfYear from "dayjs/plugin/dayOfYear";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(dayOfYear);
dayjs.extend(timezone);

dayjs.tz.setDefault("Pacific/Auckland");

export default {
  name: "Billing",
  mixins: [createdMixin],
  components: { PaymentMethodModal },
  emits: ["handleMenu"],
  async mounted() {},
  data() {
    return {
      channel: null,
      omega: 60,
      beta: 90,
      alpha: 120,
      cogs: 50,
      showPaymentModal: false,
    };
  },
  computed: {
    ...mapGetters({
      sites: "sites",
      siteMap: "siteMap",
      cogsEnabled: "cogsEnabled",
    }),

    totalStations() {
      let total = 0;
      this.siteMap.forEach((site) => {
        total += site.stations.kds.length + site.stations.pos.length;
      });
      return total;
    },

    monthlyTotal() {
      let total = 0;
      this.siteMap.forEach((site) => {
        let stationsInSite = site.stations.kds.length + site.stations.pos.length;
        if (stationsInSite <= 3 && stationsInSite > 0) {
          total += this.omega;
        } else if (stationsInSite <= 6 && stationsInSite > 3) {
          total += this.beta;
        } else if (stationsInSite >= 9) {
          total += this.alpha;
        }
      });
      return total;
    },

    siteTotal() {
      let siteResult = [];
      this.siteMap.forEach((site) => {
        let stationsInSite = site.stations.kds.length + site.stations.pos.length;
        let siteClass = "";
        let classCost = 0;
        if (stationsInSite <= 3 && stationsInSite > 0) {
          siteClass = "Small POS";
          classCost = this.omega;
        } else if (stationsInSite <= 6 && stationsInSite > 3) {
          siteClass = "Medium POS";
          classCost = this.beta;
        } else if (stationsInSite >= 9) {
          siteClass = "Large POS";
          classCost = this.alpha;
        }
        let result = site.site + ": " + stationsInSite;
        siteResult.push({ result, siteClass, classCost });
      });
      return siteResult;
    },
  },
  methods: {
    openPaymentMethodModal() {
      this.showPaymentModal = !this.showPaymentModal;
    },
  },
};
</script>

<style scoped lang="scss">
@import "public/stylesheets/layout";
@import "public/stylesheets/wrapper";
@import "public/stylesheets/actions";
h1 {
  margin: 1rem;
}
.container {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  padding-top: 1rem;
  h2,
  h1 {
    flex: 1 0 100%;
  }
  .card {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    border-radius: 10px;
    background: var(--primary-colour);
    box-shadow: var(--box-shadow);
    margin: 1rem;
    padding: 0.5rem;
    width: 100%;
    height: 100%;
    .blue {
      color: var(--action-colour);
    }
    .title {
      text-transform: capitalize;
    }
  }

  .box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    width: 20rem;
    border-radius: 5px;
    margin: 0.5rem;
    padding: 0.5rem;
    box-shadow: var(--box-shadow);
    background-color: var(--secondary-colour);
    p {
      margin: 0.5rem;
      text-transform: capitalize;
    }
    h3 {
      margin: 0.5rem;
    }
  }

  .press {
    background-color: var(--action-colour);
    box-shadow: var(--box-shadow);
    color: var(--secondary-text-colour);
    &:active {
      scale: 0.95;
    }
  }
}
@media only screen and (orientation: portrait) {
  .container {
    flex-direction: column;
    width: 90%;
    h2 {
      width: 50%;
    }
    .card {
      flex-direction: column;
      margin: 0.75rem;
    }
    .box {
      width: 90%;
    }
  }
}
</style>
