<template>
  <Authenticated>
    <section v-for="site in siteMap" :key="site" class="main">
      <h2>{{ site.site }}</h2>
      <div class="unit-container">
        <div v-for="eftpos in site.stations.pos" :key="eftpos" class="unit-list">
          <div class="unit" @click.stop.prevent="openPOSModal(eftpos)">
            <h3>{{ eftpos.name }}</h3>
            <p>IP address: {{ eftpos.eftpos.ip }}</p>
            <p>Port: {{ eftpos.eftpos.port }}</p>
            <p></p>
            <button v-if="eftpos.float" @click.stop.prevent="openFloat">Float Control</button>
          </div>
        </div>
      </div>
    </section>
    <POSStationModal :open="showPOSModal" :station="POSStation" :newStation="newStation" @update="updateStations" @close="close" />
  </Authenticated>
</template>

<script>
import Authenticated from "../_layouts/Authenticated.vue";
import POSStationModal from "../Settings/POSStationModal.vue";
import { api } from "@/helpers/api";
import { mapGetters } from "vuex";
import createdMixin from "@/components/_mixins/createdMixin";


export default {
  name: "POSPage",
  mixins: [createdMixin],
  components: {
    Authenticated,
    POSStationModal,
  },
  data() {
    return {
      showPOSModal: false,
      POSStation: {},
      newStation: false,
    };
  },
  methods: {
    close() {
      this.showPOSModal = false;
    },
    openPOSModal(incoming, newStation = false) {
      this.POSStation = {
        name: null,
        site: null,
        eftpos: {
          ip: null,
          port: null,
        },
      };
      this.newStation = newStation;
      if (newStation) {
        this.POSStation.site = incoming;
        this.showPOSModal = !this.showPOSModal;
        return;
      }
      this.POSStation = incoming;
      this.showPOSModal = !this.showPOSModal;
    },

    async updateStations(typeOfUpdate, typeOfStation, incomingStation) {
      let newStations = [];
      console.log(incomingStation, typeOfStation, typeOfUpdate);
      this.siteMap.forEach((site) => {
        if (site.site == incomingStation.site) {
          site.stations[typeOfStation].forEach((station) => {
            if (typeOfUpdate === "update") {
              if (station.name == incomingStation.name) {
                newStations.push(incomingStation);
              } else {
                newStations.push(station);
              }
            } else if (typeOfUpdate === "delete") {
              if (station.name != incomingStation.name) {
                newStations.push(station);
              }
            }
          });
        } else {
          site.stations[typeOfStation].forEach((station) => {
            newStations.push(station);
          });
        }
      });
      this.showKDSModal = false;
      this.showPOSModal = false;

      await api.post("/api/company/update_stations/" + this.$store.getters.companyName, {
        doc: newStations,
        station_type: `get_${typeOfStation}`,
        site: incomingStation.site,
      });
      this.$store.dispatch("initialSiteMap");
    },
  },
  computed: {
    ...mapGetters({
      channels: "channels",
      siteMap: "siteMap",
      siteSettings: "siteSettings",
      companySettings: "companySettings",
      timezone: "timezone",
      sites: "sites",
    }),
  },
  mounted() {
    // Lifecycle hook when the component is mounted
  },
};
</script>

<style scoped lang="scss">
@import "public/stylesheets/layout";
@import "public/stylesheets/wrapper";
@import "public/stylesheets/actions";
.header {
  margin: 1.5rem;
}
.main {
  padding: 1rem;
  box-shadow: var(--box-shadow);
  background-color: var(--primary-colour);
  margin: 2rem;
  border-radius: 10px;
  max-width: 90%;
  h2 {
    text-transform: capitalize;
  }
  .unit-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    // background-color: var(--primary-colour);
    border-radius: 20px;
    margin: 1rem;
    .unit {
      max-width: 95%;
      height: 8.5rem;
      margin: 0.5rem;
      padding: 1rem;
      box-shadow: var(--box-shadow);
      border-radius: 10px;
      background-color: var(--secondary-colour);
      cursor: pointer;
      h3 {
        text-transform: capitalize;
        margin: 0.25rem 0;
      }
      p {
        color: var(--action-colour);
      }
      button {
        position: relative;
        bottom: 1rem;
        float: right;
        margin: 0.25rem;
        padding: 0.5rem;
        background-color: var(--action-colour);
        color: var(--primary-colour);
        font-size: smaller;
        box-shadow: var(--box-shadow);
        &:active {
          box-shadow: none;
          translate: transform3d(0, 0, 0);
        }
      }
      &:active {
        box-shadow: none;
        translate: transform3d(0, 0, 0);
      }
    }
  }
}

@media only screen and (orientation: portrait) {
  .main {
    padding: 0.5rem 0;
    margin: 1rem 0;
    border-radius: 0;
    max-width: 100%;
    .unit-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
