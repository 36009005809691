<template>
  <Unauthenticated>
    <div class="top-margin">
      <form v-on:submit.prevent="emailResetLink">
        <svg
          id="Layer_1"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 841.89 595.28"
        >
          <path
            class="cls-1"
            d="M168.15,378.25c8.47-11.18,20.12-16.45,32.42-16.45,22.84,0,35.93,14.37,25.87,54.77-8.62,34.33-22.52,47.91-45.67,47.91-19,0-26.83-7.51-29.86-15.65l-1.12.16c-.32,1.76-.64,3.67-1.12,5.43-.36,1.39-.7,2.68-1,3.94a5.86,5.86,0,0,1-5.69,4.52H116a5.84,5.84,0,0,1-5.65-7.33c3.9-14.81,8.08-31.28,13.55-53,6.08-24.61,10.85-43.84,14.42-58.76a5.83,5.83,0,0,1,5.68-4.47h26.1a5.84,5.84,0,0,1,5.65,7.32c-1.23,4.67-2.48,9.62-3.94,15.67-1.6,6.23-2.72,10.54-4.32,15.81Zm-14.3,52.46a2.79,2.79,0,0,0,1.58,3.23A30.16,30.16,0,0,0,168,436.37c11.34,0,17.73-6.06,22.2-23.95,4.47-18,.16-23-10.06-23-8.62,0-17.25,4.79-20.92,20.12Z"
          />
          <path
            class="cls-1"
            d="M333.66,404.44c-1.2,5.19-2.73,10.39-4,14.29a3.07,3.07,0,0,1-3,2.09c-9.82-.25-23.48-.25-35.77-.25-8.49,0-16.38,0-22.49.1a3.06,3.06,0,0,0-3,2.64c-1.79,13.21,2.48,18,12.55,18,8.65,0,12.87-3.26,15.26-9.88a3.09,3.09,0,0,1,3.08-2.09c3.49.2,8,.31,13.27.31q7.5,0,13.66-.27a3.09,3.09,0,0,1,3.1,3.89C320.79,454.36,309.14,465,274.89,465c-37.84,0-51.57-18.52-42.15-55.89,8.46-34.18,28.1-47.75,59.72-47.75C331.58,361.32,339.72,380.33,333.66,404.44Zm-33.54.17c.27-1.07.56-2.17.95-3.5l0-.05c2.7-11.16.15-16.74-10.39-16.74-9.15,0-15.3,4.37-20.47,19.43a1,1,0,0,0,.94,1.37c8.57.27,18.22.27,28.15.13A.84.84,0,0,0,300.12,404.61Z"
          />
          <path
            class="cls-1"
            d="M451.25,363.4a3.65,3.65,0,0,1,3.53,4.6c-2.36,8.83-5.41,21.15-10.39,41.07-5.42,21.82-9,35.7-12.46,51.12a3.66,3.66,0,0,1-3.57,2.85H397.79a3.65,3.65,0,0,1-3.53-4.58c.51-2,1-4,1.58-6.12s1.14-4.37,1.82-6.64a.85.85,0,0,0-.62-1.06h0a.84.84,0,0,0-.87.35c-6.73,10.2-17.49,19.33-32.75,19.33-23.47,0-34.49-18.2-24.59-58,8-31.93,25.71-44.39,45.51-44.39,14.22,0,24.44,5,29.71,13.89l.79-.16.8-3c.56-2.45,1-4.43,1.55-6.49a3.65,3.65,0,0,1,3.55-2.77ZM411,393.77a2.63,2.63,0,0,0-1.6-3.08,35.16,35.16,0,0,0-12.89-2.22c-11,0-18,5.75-22.19,23-4.8,19-.65,25.07,9.26,25.07,9.42,0,18.36-6.06,21.71-19.8Z"
          />
          <path
            class="cls-1"
            d="M553.92,384c9.74-16.13,21.71-22,34-22,20,0,27.62,13.57,23.63,30.18-4,16.45-7,26.51-10.06,39.12-2.58,10.19-4.91,20-6.74,28.71a3.67,3.67,0,0,1-3.6,2.91H564.34a3.67,3.67,0,0,1-3.55-4.64c3.88-14.08,8.4-31.45,13.57-52.69,1.91-8.14-1.28-13.09-9-13.09s-14.21,4.47-17.72,18.2c-6.42,25.68-9.78,38.7-11.89,49.28a3.66,3.66,0,0,1-3.6,2.94H505.76a3.67,3.67,0,0,1-3.54-4.66c3.77-13.44,7.83-29.11,13.69-52.83,1.6-7-.64-12.93-8.62-12.93s-14.69,4.47-18.21,18.2c-6.1,24.58-9.69,39.22-12,49.36a3.65,3.65,0,0,1-3.58,2.86H446.27a3.67,3.67,0,0,1-3.55-4.61c3.38-12.87,6.7-26.21,11.55-44.89,4.77-20,8.16-33.71,11.31-47.15a3.68,3.68,0,0,1,3.58-2.83H497a3.68,3.68,0,0,1,3.54,4.68c-1.37,4.84-2.49,9.07-4.08,14.32h.8c9.1-13.89,19-20.6,33.37-20.6,13.89,0,22.68,8.31,22.52,22Z"
          />
          <path
            class="cls-1"
            d="M727.66,366.07a3.4,3.4,0,0,1,3,5c-12.64,23.13-39,77.36-47.4,92.12-15.33,27.63-28.11,40.24-55.41,40.24-14.27,0-23.58-1.88-31.7-4.84a4,4,0,0,1-2.27-5.29c2.35-5.47,6.22-14.76,8.48-20.47a4,4,0,0,1,4.85-2.32c6.47,2,14.6,2.9,22.39,2.9,8.86,0,13.77-2.26,18.17-8.9a.84.84,0,0,0-.69-1.32c-8,0-15-.09-19.58,0a3.26,3.26,0,0,1-3.35-3.08c-.89-16.61-2.91-67.82-4.52-90.45a3.4,3.4,0,0,1,3.39-3.64h31.3a3.39,3.39,0,0,1,3.39,3.48c-.27,10.92,0,37.24-.32,51.2-.16,9.74-.8,18.52-1.28,25.71h.8c3.35-7.19,7.5-16,12.3-25.87,6.91-14.56,19.43-43,23.62-52.5a3.37,3.37,0,0,1,3.09-2Z"
          />
          <path
            class="cls-1"
            d="M525.31,163.07a15.92,15.92,0,0,0-9.79,3.36,111,111,0,0,0-202.12-.87,16,16,0,0,0-24.58,13.53v57.83a16,16,0,0,0,16,16,15.79,15.79,0,0,0,5.77-1.09,111.06,111.06,0,0,0,207.55-.61,16,16,0,0,0,23.17-14.32V179.09A16,16,0,0,0,525.31,163.07ZM340.74,260.44a4.71,4.71,0,0,1-.73-2.9l.26-3.86a.41.41,0,0,1,.77-.18c2.49,4.44,11.61,19.68,23.15,26.56a57.12,57.12,0,0,0,7.25,3,24.63,24.63,0,0,1-7.25-3C357.4,276.76,347.33,270.55,340.74,260.44Zm95.49,18.32H398.82a60.11,60.11,0,0,1-48.25-24c-10.37-13.91-19.52-35.06-19.52-66.42,0,0,3.11-25.45,30.79-25.45h97.08a43.08,43.08,0,0,1,21.21,5.32c6.92,3.89,13.83,10.26,13.83,20.13C494,208.1,490.33,278.76,436.23,278.76Zm8.83-155a4,4,0,0,1-3.16-3.93v-3.6a1.86,1.86,0,0,1,2.4-1.79c8.52,2.57,37.94,13.78,57.69,50.64C502,165.11,476.75,131.13,445.06,123.79Z"
          />
          <path
            class="cls-1"
            d="M398.54,215.08c-21.8,1.61-24.33,4.14-25.94,25.94a.31.31,0,0,1-.61,0c-1.62-21.8-4.14-24.33-25.95-25.94a.31.31,0,0,1,0-.61c21.81-1.62,24.33-4.14,25.95-25.95a.31.31,0,0,1,.61,0c1.61,21.81,4.14,24.33,25.94,25.95A.31.31,0,0,1,398.54,215.08Z"
          />
          <path
            class="cls-1"
            d="M413.85,189.91c-13.71.94-14.86,2.1-15.8,15.8a.09.09,0,0,1-.17,0c-.94-13.7-2.1-14.86-15.8-15.8a.09.09,0,0,1,0-.17c13.7-.94,14.86-2.1,15.8-15.8a.09.09,0,0,1,.17,0c.94,13.7,2.09,14.86,15.8,15.8A.09.09,0,0,1,413.85,189.91Z"
          />
          <path
            class="cls-1"
            d="M509,278.66l-5,7.67a6.21,6.21,0,0,1-2,1.94c-8.33,5-57,33.55-86.13,33.55s-80.94-28.91-89.76-34a6.09,6.09,0,0,1-2.09-2l-4.93-7.63a2.62,2.62,0,0,0-2.19-1.19h-4.72a3.92,3.92,0,0,0-3.91,3.92v24.83a3.87,3.87,0,0,0,2.39,3.58c13.39,5.49,79.26,31.94,105.75,31.94s87.92-25.87,100.94-31.47a4,4,0,0,0,2.44-3.71V281.28a3.8,3.8,0,0,0-3.8-3.81H511.2A2.61,2.61,0,0,0,509,278.66ZM515.57,303a2.7,2.7,0,0,1-1.58,2.4c-54.22,26.38-73.26,26-73.26,26,49.21-16.4,66.75-27.32,72.41-31.72a1.5,1.5,0,0,1,2.43,1.19Z"
          />
        </svg>
        <div v-if="!formSubmitted">
          <h4>Please enter your user email:</h4>
          <small>This is the email you used to sign up with Beamy</small>
          <input
            name="email"
            v-model="email"
            placeholder="Email Address"
            autofocus
            autocomplete="username"
          />
          <button type="submit" value="ResetLink">
            Send password reset email
          </button>
        </div>
        <div v-if="formSubmitted" class="link-sent">
          <h2>Reset link sent</h2>
          <p>
            We have sent you an email with a link to reset your password. Please
            check your email.
          </p>
        </div>

        <div class="error" v-if="errors && errors.email">
          Email Address {{ errors.email.join(", ") }}
        </div>

        <div class="error" v-if="errors && errors.all">{{ errors.all }}</div>
      </form>

      <div v-if="loading" class="spinner">
        <LoadingSpinner :spinnerMessage="'Retrieving Data...'" />
      </div>
    </div>
  </Unauthenticated>
</template>

<script>
import Unauthenticated from "@/components/_layouts/Unauthenticated";
import LoadingSpinner from "@/components/_layouts/LoadingSpinner";
import { api } from "@/helpers/api";

export default {
  name: "PasswordReset",
  components: { Unauthenticated, LoadingSpinner },
  data() {
    return {
      email: "",
      errors: null,
      loading: false,
      formSubmitted: false,
      
    };
  },
  methods: {
    async emailResetLink() {
      this.loading = true;
      const res = await api.email_password_reset(this.email);
      if (res == "ok") {
        this.loading = false;
        this.formSubmitted = true;
      } else {
        this.loading = false;
        this.errors =
          "There appears to be an error sending the reset link, please try again";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.loading-spinner {
  position: absolute;
  left: 0;
  top: 0;
  background-color: var(--opacity-colour);
  color: var(--secondary-text-colour);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
form {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0.5rem;
  min-width: 25vw;
  max-width: 40vw;
  padding: 3rem;
  margin: 0px auto;
  margin-top: 1rem;
  position: relative;
  background-color: var(--secondary-colour);
  font-size: 1em;
  box-shadow: var(--box-shadow);
  .cls-1 {
    fill: var(--action-colour);
  }
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    h4, p {
      text-align: center;
      margin: 1rem;
    }

    a {
      color: var(--text-colour);
      text-decoration: none;
    }

    small {
      margin: 0.25rem 0;
    }

    //a:v
    button {
      margin-bottom: 1rem;
      padding: 1rem;
      width: 100%;
      background-color: lavender;
      font-weight: bold;
      border-radius: 0.25rem;
      background-color: var(--action-colour);
      color: var(--primary-colour);
    }

    input,
    button {
      font-size: 1rem;
      border: none;
    }
    input {
      font-size: 1rem;
      box-sizing: border-box;
      padding: 1em;
      width: 100%;
      border-radius: 5px;
      box-shadow: var(--box-shadow);
    }

    p.or {
      text-align: center;
    }

    .btn-outline-info:hover {
      background-color: inherit;
    }
  }
}

form div.error {
  display: block;
  margin-bottom: 1em;
  color: #ff8197;
}

form input {
  position: relative;
  margin-bottom: 1rem;
}

form input,
form button {
  display: block;
}

.password {
  display: block;
  text-align: right;
  font-size: 0.8em;
  margin-top: 10px;
}

@media screen and (max-width: 840px) {
  form {
    border: none;
    padding: 2em;
    // max-width: none !important;
  }
}
</style>
