<template>
  <div class="chart-container">
    <VChart :option="BarChartOptions" class="chart" autoresize></VChart>
  </div>
  <!-- <div v-for="data in stackedData" :key="data">{{ data.key }} {{ data.value }}</div> -->
</template>

<script>
import * as echarts from "echarts/core";
import { GridComponent, TitleComponent, LegendComponent, TooltipComponent } from "echarts/components";
import { LineChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import VChart from "vue-echarts";
import { api } from "@/helpers/api";

echarts.use([CanvasRenderer, LineChart, GridComponent, LegendComponent, TitleComponent, TooltipComponent]);

export default {
  name: "App",
  components: {
    VChart,
  },
  data() {
    return {
      companyName: localStorage.getItem("company"),
      stackedData: [],
      labels: [],
      dataSet: [],
      series: [],
      xName: "Hour",
      yName: "$",
      catAxis: [],
    };
  },
  props: {
    site: {
      type: String,
      required: true,
    },
    dataType: {
      type: String,
      required: true,
    },
    period: {
      type: String,
      required: true,
    },
    day: {
      type: Array,
      required: true,
    },
    otherDay: {
      type: Array,
      required: true,
    },
  },

  computed: {
    BarChartOptions() {
      return {
        xAxis: {
          name: this.xName,
          data: this.catAxis,
          type: "category",
          nameLocation: "middle",
          nameGap: 30,
          nameTextStyle: {
            color: "#3bafda",
            fontSize: 16,
          },
        },
        yAxis: {
          type: "value",
          name: this.yName,
          nameLocation: "middle",
          nameRotate: 360,
          nameGap: 50,
          show: true,
          nameTextStyle: {
            color: "#3bafda",
            fontSize: 16,
            width: "100%",
          },
        },
        series: this.series,
        legend: {
          textStyle: {
            color: "#3bafda",
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // Use axis to trigger tooltip
            type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
          },
        },
        grid: {
          containLabel: true,
        },
      };
    },
  },

  methods: {
    async collectData() {
      let start = this.day[0];
      let end = this.day[1];
      let temp = [];
      let temp2 = [];
      let catAxis = [];

      if (this.dataType == "sales") {
        this.stackedData = await api.get_stacked_data(this.companyName, this.site, start, end);
      } else {
        this.stackedData = await api.get_stacked_count(this.companyName, this.site, start, end);
      }
      this.labels = this.stackedData.map((data) => this.utcFix(data.key[0]));
      this.dataSet = this.stackedData.map((data) => data.value);
      this.stackedData.map((data1) => {
        if (!catAxis.includes(this.utcFix(data1.key[0]))) {
          // catAxis.push(data1.key[0].slice(11, 13));
          catAxis.push(this.utcFix(data1.key[0]));
        }
        if (!temp.includes(data1.key[1])) {
          temp.push(data1.key[1]);
          temp2.push({
            name: data1.key[1],
            data: [data1.value],
            type: "bar",
            stack: "total",
            areaStyle: {},
            emphasis: {
              focus: "series",
            },
            label: {
              show: true,
            },
          });
        } else {
          temp2.forEach((m) => {
            if (m.name == data1.key[1]) {
              m.data.push(data1.value);
            }
          });
        }
      });
      this.series = temp2;
      this.catAxis = catAxis;
    },

    utcFix(date) {
      const hour = parseInt(date.slice(11, 13));
      const day = date.slice(0, 10);
      const timezoneOffset = new Date(day).getTimezoneOffset() / 60;
      return (hour - timezoneOffset) % 24;
    },
  },

  watch: {
    site: function() {
      this.collectData();
    },
    dataType: function() {
      this.collectData();
    },
    currentDay: function() {
      this.collectData();
    },
  },

  mounted() {
    this.collectData();
  },
};
</script>

<style scoped lang="scss">
.chart-container {
  height: 60vh;
  width: 60vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media only screen and (orientation: portrait) {
  .chart-container {
    width: 100%;
    padding: 2.55px;
  }
}
</style>
