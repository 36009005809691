<template>
  <teleport to="body">
    <div class="modal" v-if="showModal" @click.self.prevent="showModal != showModal">
      <div class="wrapper">
        <font-awesome-icon icon="times" class="exit" @click.stop.prevent="closed" />
        <section class="user">
          <div class="user-info">
            <h2>{{ user.value.fname }} {{ user.value.lname }}</h2>
            <h3>{{ user.value.email }}</h3>
            <h4>No. orders: {{ user.key }}</h4>
          </div>
          <!-- <h4>User Orders:</h4> -->
          <div class="buttons">
            <button @click.stop.prevent="orders(false)">previous</button>
            <button @click.stop.prevent="orders(true)">next</button>
          </div>
          <!-- {{ orderData }} -->
          <div class="user-orders">
            <ul>
              <li v-for="order in orderData" :key="order" @click.stop.prevent="expandOrder(order.docs[0].ok._id)">
                <span>{{ new Date(order.docs[0].ok.events.created_at).toDateString() }}</span>
                <span class="float">${{ order.docs[0].ok.total }}</span>
                <div class="details" :id="order.docs[0].ok._id">
                  <ul class="orders">
                    <li v-for="item in order.docs[0].ok.products" :key="item" class="item">
                      <span>{{ item.name }}</span>
                      <span class="float">${{ item.price }}</span>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </section>
      </div>
    </div>
  </teleport>
</template>

<script>
import { mapGetters } from "vuex";
import { api } from "@/helpers/api";
export default {
  name: "UserOrderModal",
  data() {
    return {
      showModal: false,
      currentPag: 0,
      userOrders: [],
      orderData: [],
      showDetails: false,
    };
  },
  emits: ["closeModal"],

  props: {
    user: {
      type: Object,
      required: true,
    },
    open: {
      type: Boolean,
      required: true,
    },
  },

  watch: {
    async open() {
      if (!this.open) {
        this.showModal = false;
        return;
      }
      this.showModal = true;
      this.orders(true);
    },
  },

  methods: {
    expandOrder(id) {
      document.getElementById(id).classList.toggle("details");
    },

    closed() {
      this.$emit("closeModal");
      this.currentPag = 0;
      this.showModal = false;
    },

    async getOrders() {
      let userOrders = [];
      this.userOrders.map((id) => userOrders.push({ id: id }));
      let result = await api.get_user_orders("bagels" + "_all_orders", { docs: userOrders });
      this.orderData = result.results;
    },

    orders(pagination) {
      let orders = this.user.value.orders.toReversed();
      this.currentPag = pagination ? (this.currentPag += 10) : (this.currentPag -= 10);
      this.userOrders = pagination ? orders.slice(this.currentPag, this.currentPag + 10) : orders.slice(this.currentPag - 10, this.currentPag);
      this.getOrders();
    },
  },
  computed: {
    ...mapGetters({
      sites: "sites",
      companyName: "companyName",
    }),
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
@import "public/stylesheets/wrapper";
@import "public/stylesheets/actions";
@import "public/stylesheets/buttons";
@import "public/stylesheets/global";
@import "public/stylesheets/layout";

.wrapper {
  .user {
    color: var(--text-colour);
    max-height: 80vh;

    .buttons {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin: 0;

    }
    button {
        width: 100%;
        margin: 1rem;
        padding: 0.5rem;
        background-color: var(--action-colour);
        color: var(--secondary-text-colour);
        border-radius: 5px;
        border: none;
        box-shadow: var(--box-shadow);
        text-transform: capitalize;
        cursor: pointer;
      }
    .user-orders {
      display: flex;
      align-items: center;
      justify-content: center;
      ul {
        padding-inline-start: 0;
      }
      li {
        list-style: none;
        box-shadow: var(--box-shadow);
        padding: 1rem;
        margin: 1rem;
        border-radius: 10px;
        background: var(--primary-colour);
        width: 20rem;
        cursor: pointer;
        .float {
          float: right;
        }
        .details {
          display: none;
        }
        .orders {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin: 1rem 0 0 0;
          .item {
            padding: 1rem 0.5rem;
            margin: 0.5rem;
            color: var(--action-colour);
          }
        }
      }
    }
  }
}
</style>
